import { useState } from 'react';
import { FaBars, FaTimes, FaGithub, FaLinkedin } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { Link } from 'react-scroll';
import i18n, { t } from 'i18next';

import logo from '#src/assets/logo.png'
import pdf from '#src/assets/TugayDemirel-CV.pdf'

const Navbar = () => {
    const [nav, setNav] = useState(false);
    const [lang, setLang] = useState("en");
    const [shownLang, setShownLang] = useState("tr");
    const handleClick = () => setNav(!nav);

    const handleLang = () => {
        setLang(lang === "en" ? "tr" : "en")
        setShownLang(shownLang === "tr" ? "en" : "tr");
        i18n.changeLanguage(shownLang);
    };

    return (
        <div className="fixed w-full h-[80px] flex justify-between items-center px-4 bg-[#0a192f] text-gray-300 cursor-pointer">
            <div>
                <Link to="home" smooth={true} duration={500}>
                    <img src={logo} alt="logo" className='h-[80px]' />
                </Link>
            </div>

            {/* Menu */}
            <ul className='hidden md:flex'>
                <li><Link to="home" smooth={true} duration={500}>{t("home")}</Link></li>
                <li><Link to="about" smooth={true} duration={500}>{t("about")}</Link></li>
                <li><Link to="skills" smooth={true} duration={500}>{t("skills")}</Link></li>
                <li><Link to="projects" smooth={true} duration={500}>{t("projects")}</Link></li>
                <li><Link to="contact" smooth={true} duration={500}>{t("contact")}</Link></li>
                <li><button className='uppercase' onClick={() => handleLang()}>{shownLang}</button></li>
            </ul>

            {/* Hamburger */}
            <div onClick={handleClick} className='md:hidden z-10'>
                {!nav ? <FaBars /> : <FaTimes />}
            </div>

            {/* Mobile Menu */}
            <ul className={!nav ? 'hidden' : 'absolute top-0 left-0 w-full h-screen bg-[#0a192f] flex flex-col justify-center items-center'}>
                <li className='py-6 text-4xl'><Link onClick={handleClick} to="home" smooth={true} duration={500}>{t("home")}</Link></li>
                <li className='py-6 text-4xl'><Link onClick={handleClick} to="about" smooth={true} duration={500}>{t("about")}</Link></li>
                <li className='py-6 text-4xl'><Link onClick={handleClick} to="skills" smooth={true} duration={500}>{t("skills")}</Link></li>
                <li className='py-6 text-4xl'><Link onClick={handleClick} to="projects" smooth={true} duration={500}>{t("projects")}</Link></li>
                <li className='py-6 text-4xl'><Link onClick={handleClick} to="contact" smooth={true} duration={500}>{t("contact")}</Link></li>
                <li><button className='py-6 text-4xl uppercase' onClick={() => handleLang()}>{shownLang}</button></li>
            </ul>

            {/* Social icons */}
            <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
                <ul>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-blue-600'>
                        <a className='flex justify-between items-center w-full text-gray-300' href="https://linkedin.com/in/tugaydemirel" target="_blank" rel="noopener noreferrer">
                            Linkedin <FaLinkedin size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#333]'>
                        <a className='flex justify-between items-center w-full text-gray-300' href="https://github.com/tugaydmrl" target="_blank" rel="noopener noreferrer">
                            Github <FaGithub size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]'>
                        <a className='flex justify-between items-center w-full text-gray-300' href="mailto:info@tugaydemirel.com">
                            Email <HiOutlineMail size={30} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-[#565f69]'>
                        <a className='flex justify-between items-center w-full text-gray-300' href={pdf}>
                            {t("resume")} <BsFillPersonLinesFill size={30} />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar