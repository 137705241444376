import { useTranslation } from 'react-i18next'

const Contact = () => {
    const { t } = useTranslation()
    return (
        <div name="contact" className="w-full h-screen bg-[#0a192f] flex justify-center items-center p-4">
            <form action="" className="flex flex-col max-w-[600px] w-full">
                <div className="pb-8">
                    <p className="text-4xl font-bold inline border-b-4 border-[#28afb0] text-gray-300">{t("contact")}</p>
                    <p className="text-gray-300 py-4">{t("submit_form")} - <a className="text-[#28afb0] hover:text-white" href="mailto:contact@tugaydemirel.com">contact@tugaydemirel.com</a></p>
                </div>
                <input className="bg-white p-2" type="text" placeholder={t("name")} name="name" />
                <input className="my-4 p-2 bg-white" type="email" placeholder="Email" name="email" />
                <textarea className="bg-white p-2" name="message" rows="10" placeholder={t("message")}></textarea>
                <button title={t("formmail")} className="text-white border-2 hover:bg-[#333] hover:border-[#333] hover:cursor-not-allowed px-16 py-3 my-8 mx-auto flex items-center" disabled>{t("send")}</button>
            </form>
        </div>
    )
}

export default Contact