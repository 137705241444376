import { useTranslation } from "react-i18next"

const ProjectItem = ({ src, title, liveUrl, codeUrl }) => {
    const { t } = useTranslation()
    return (
        <div style={{ backgroundImage: `url(${src})` }} className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div">

            {/* Hover Effects */}
            <div className="opacity-0 group-hover:opacity-100">
                <span className="text-2xl font-bold text-white tracking-wider">
                    {title}
                </span>
                <div className="pt-8 text-center">
                    {liveUrl && <a href={liveUrl}>
                        <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">{t("live_demo")}</button>
                    </a>}
                    <a href={codeUrl}>
                        <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">{t("code")}</button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default ProjectItem