import { HiArrowNarrowRight } from 'react-icons/hi'
import { useTranslation } from 'react-i18next'

const Home = () => {
    const { t } = useTranslation()
    return (
        <div name="home" className='bg-[#0a192f] w-full h-screen'>

            {/* Container */}
            <div className='max-w-[1000px] mx-auto px-9 flex flex-col justify-center h-full'>
                <p className='text-[#28afb0]'>{t("hello")}</p>
                <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>Tugay Demirel</h1>
                <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>{t("i_am_developer")}</h2>
                <p className='text-[#8892b0] py-4 max-w-[700px]'>{t("about_me")}</p>
                <div>
                    <a href="https://github.com/tugaydmrl" target="_blank" rel="noopener noreferrer">
                        <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-[#28afb0] hover:border-[#28afb0]'>{t("view_works")}
                            <span className='group-hover:ml-1 duration-300'>
                                <HiArrowNarrowRight className='ml-3' />
                            </span>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Home