import { useTranslation } from 'react-i18next'

import SkillItem from './SkillItem'
import HTML from '#src/assets/html.png'
import CSS from '#src/assets/css.png'
import JavaScript from '#src/assets/javascript.png'
import ReactImg from '#src/assets/react.png'
import Node from '#src/assets/node.png'
import Github from '#src/assets/github.png'
import Tailwind from '#src/assets/tailwind.png'
import Mongo from '#src/assets/mongo.png'

const Skills = () => {
    const { t } = useTranslation()
    return (
        <div name="skills" className='w-full h-screen bg-[#0a192f] text-gray-300'>
            {/* Container */}
            <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
                <div>
                    <p className='text-4xl font-bold inline border-b-4 border-[#28afb0]'>{t("languages_and_tools")}</p>
                    <p className='py-4'>{t("technologies_worked")}</p>
                </div>
                {/* Container of all icons */}
                <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
                    <SkillItem name='HTML' src={HTML} />
                    <SkillItem name='CSS' src={CSS} />
                    <SkillItem name='JavaScript' src={JavaScript} />
                    <SkillItem name='React' src={ReactImg} />
                    <SkillItem name='Node' src={Node} />
                    <SkillItem name='GitHub' src={Github} />
                    <SkillItem name='Tailwind' src={Tailwind} />
                    <SkillItem name='MongoDB' src={Mongo} />
                </div>
            </div>
        </div>
    )
}

export default Skills