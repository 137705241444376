import Navbar from '#src/components/Navbar';
import Home from '#src/components/Home';
import About from '#src/components/About';
import Skills from '#src/components/Skills';
import Projects from '#src/components/Projects';
import Contact from '#src/components/Contact';

function App() {
  return (
    <div>
      <Navbar />
      <Home />
      <About />
      <Skills />
      <Projects />
      <Contact />
    </div>
  );
}

export default App;
