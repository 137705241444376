import { useTranslation } from "react-i18next"

import MernEcommerce from "#src/assets/mernecommerce.jpg"
import MernNetflix from "#src/assets/login.jpg"
import RedditClone from "#src/assets/reddit.jpg"
import WeatherForecast from "#src/assets/weatherForecast.jpg"
import MemoryGame from "#src/assets/memorygame.jpg"
import Taskman from "#src/assets/taskman.jpg"
import ProjectItem from "./ProjectItem"


const Work = () => {
    const { t } = useTranslation()
    return (
        <div name="projects" className="w-full md:h-screen text-gray-300 bg-[#0a192f]">
            <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
                <div className="pb-8">
                    <p className="text-4xl font-bold inline border-b-4 border-[#28afb0]">{t("projects")}</p>
                    <p className="py-6">{t("check_out_my_works")}</p>
                </div>

                <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                    <ProjectItem src={RedditClone} title="Reddit Clone" liveUrl="https://reddit.tugaydemirel.com/" codeUrl="https://github.com/tugaydmrl/reddit-clone" />
                    <ProjectItem src={MernNetflix} title="MERN Netflix" codeUrl="https://github.com/tugaydmrl/mern-netflix" />
                    <ProjectItem src={MernEcommerce} title="MERN Ecommerce" codeUrl="https://github.com/tugaydmrl/mern-ecommerce" />
                    <ProjectItem src={MemoryGame} title="Memory Game" liveUrl="https://memorygame.tugaydemirel.com/" codeUrl="https://github.com/tugaydmrl/React-Memory-Game" />
                    <ProjectItem src={WeatherForecast} title="MERN Ecommerce" liveUrl="https://tugaydmrl.github.io/weather-forecast-app/" codeUrl="https://github.com/tugaydmrl/weather-forecast-app" />
                    <ProjectItem src={Taskman} title="Taskman" liveUrl="https://taskman.tugaydemirel.com/" codeUrl="https://github.com/tugaydmrl/taskman" />
                </div>
            </div>
        </div>
    )
}

export default Work